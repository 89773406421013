import React from 'react';
import { graphql } from 'gatsby';

import HtmlHead from '../components/html-head';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { FluidChildImageSharp, PageData } from '../types';

import '../styles/index.css';
import TestimonialBlock from '../components/components-group-one/TestimonialBlock';
import TrustedClientLogos from '../components/home-logos';
import GetStartedHero from '../components/get-started-hero';

const GetStarted: React.FC<PageData> = resp => {
    const seoData = resp.data.allContentfulPageSeoData.edges[0].node;
    const testimonialData = [
        {
            company: "everbridge",
            name: "Renee Bastine",
            bio: "Sr. Director, Global Customer Support",
            //@ts-ignore
            logo: resp.data.testimonialLogo.childImageSharp.gatsbyImageData,
            content: "Our CSAT has been 99% since we put AptEdge in use. With AptEdge we were able to get answers to our customers quickly and accurately.",
        },
    ]

    return (
        <>
            <HtmlHead
                title={seoData.title}
                metaDescription={seoData.metaDescription.metaDescription}
                canonicalLink={seoData.canonicalLink}
                openGraphImage={seoData.openGraphImage.file.url}
            />
            <Navbar transparent />
            <GetStartedHero />
            <TrustedClientLogos />
            <TestimonialBlock testimonialData={testimonialData} />
            <Footer />
        </>
    );
};

export default GetStarted;

export const query = graphql`
    query GetStartedQuery {
        allContentfulPageSeoData(filter: { pagePath: { eq: "/get-started" } }) {
            edges {
                node {
                    title
                    canonicalLink
                    metaDescription {
                        metaDescription
                    }
                    openGraphImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
        testimonialLogo: file(relativePath: { eq: "utkarsh/logo-everbridge.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: NONE)
            }
        }
    }
`;
