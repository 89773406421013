import { GatsbyImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import "./testimonial.css"
//  import { useKeenSlider } from "keen-slider/react";
// import "keen-slider/keen-slider.min.css";
import { testimonialType } from '../../../templates/use-cases';
import AppButton from '../../CustomComponents/app-button';

interface testimonialSectionType {
    testimonialData: testimonialType[];
    showCTA?: boolean;
}

const TestimonialBlock: React.FC<testimonialSectionType> = ({ testimonialData, showCTA }) => {
    // const [currentSlide, setCurrentSlide] = useState(0)
    // const [loaded, setLoaded] = useState(false)
    // const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    //     initial: 0,
    //     slideChanged(slider) {
    //         setCurrentSlide(slider.track.details.rel)
    //     },
    //     created() {
    //         setLoaded(true)
    //     },
    // });

    return (
        <div className='testimonial-block'>
            <div style={{ display:"flex",justifyContent:"space-between",alignContent:"center",paddingBottom:"30px",paddingRight:"6em",paddingLeft:"5em"}} className='testimonial-top'>

           
            {
                showCTA && <h2 className='text-h2 testi-1' style={{ textAlign: 'center', }}>See what our customers say</h2>
            }
              {
                showCTA &&
                <div style={{ width: "fit-content", }} className='testi-1'>
                    <AppButton
                        variant='variant-white'
                        linkTo="/blog/everbridge-achieves-99-customer-satisfaction-with-help-from-aptedge/"
                        style={{ backgroundColor: "#EFF5FF", border: '1px solid #EFF5FF' }}
                        text="Read Case Study"
                    />
                </div>
            }
             </div>
            {/* {testimonialData.map((eachSlide, index) => */}
            <div className='testimonialcard'>
                <div className='container'>
                <StaticImage
                        alt="everbridge logo"
                        src="../../../assets/home/dot.png"
                        className="home-logo-item"
                        loading="eager"
                        layout="constrained"
                        quality={90}
                        placeholder="none"
                        imgClassName="image-style"

                    />
                    <h3 className='text-h2' style={{ color: "var(--text-black)" }}>
                        {testimonialData[0].content}
                    </h3>
                    <p className='bold' style={{ fontSize: "2.5em", marginTop: "0.75em", fontWeight: "bold" }}>{testimonialData[0].name}</p>
                    <p className='text-md-new' >{testimonialData[0].bio}</p>
                    <div className='logo-container' style={{  marginTop: "1em" }}>
                        <GatsbyImage image={testimonialData[0].logo} alt={''} />
                    </div>
                </div>
            </div>
            {/* } */}
            {/* --- slider parent ---  */}
            {/* <div ref={sliderRef} className="keen-slider">

                 {testimonialData.map((eachSlide, index) => */}
            {/* <div className={`keen-slider__slide number-slide${index + 1}`}> */}
            {/* <div className='testimonialcard'> */}
            {/* <div className='container'> */}
            {/* <h3 className='quoted-text'> */}
            {/* {eachSlide.content} */}
            {/* </h3> */}
            {/* <p className='name'>{eachSlide.name}</p> */}
            {/* <p>{eachSlide.bio}</p> */}
            {/* <div className='logo-container'> */}
            {/* <GatsbyImage image={eachSlide.logo} alt={''} /> */}
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
            {/* </div> */}
            {/* )} */}
            {/* </div> */}

            {/*  ----  slider dots ----  */}
            {/* <div className='dots-container'> */}
            {/* {loaded && instanceRef.current && ( */}
            {/* <div className="dots"> */}
            {/* { */}
            {/* [
                                  ...Array(instanceRef.current.track.details.slides.length).keys(),
                              ].map((idx) => {
                                  return (
                                      <button
                                          key={idx}
                                          onClick={() => {
                                              instanceRef.current?.moveToIdx(idx)
                                          }}
                                          className={"dot" + (currentSlide === idx ? " active" : "")}
                                      > */}
            {/* </button> */}
            {/* )
                              })
                          } */}
            {/* </div>)} */}
            {/* </div> */}
          
        </div>
    )
}

export default TestimonialBlock;